import React, { useState } from "react";
import "./index.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Button from "../../../components/Button";
import { useTable, useGlobalFilter, useFilters } from "react-table";
import format from "date-fns/format";
import Modal from "../../../components/Modal";

export default ({ setSelectedFund, selectedFund }) => {
  // const selectedFund = useSelector((state) => state.layout.fund);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(undefined);
  const data = React.useMemo(
    () => selectedFund.companies,
    [selectedFund.companies]
  );
  const tabsData = [
    {
      label: "Metrics Dashboard",
      addButtonLabel: undefined,
    },
    {
      label: "Companies",
      addButtonLabel: "Add Company +",
    },
    {
      label: "Advisory Entity ( AE )",
      addButtonLabel: "Add AE +",
    },
    {
      label: "Entities / LLC",
      addButtonLabel: "Add Entities / LLC +",
    },
    {
      label: "ME",
      addButtonLabel: "Add ME +",
    },
  ];
  const [selectedTab, setSelectedTab] = useState(tabsData[0]);
  console.log("selectedFund", selectedFund, "selectedCompany", selectedCompany);
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "% STAKE",
        accessor: "stake",
      },
      {
        Header: "Amount Invested",
        accessor: "investedAmount",
      },
      {
        Header: "FMV of Company",
        accessor: "fmv",
      },
      {
        Header: "fmv of amount invested",
        accessor: "investedAmountFmv",
      },
      {
        accessor: "id",
        Cell: (props) => {
          return (
            <>
              <Button
                variant="outlined"
                size="s"
                onClick={() => {
                  setModalVisibility(true);
                  setSelectedCompany(props.data[props.row.index]);
                }}
              >
                View Cap Table
              </Button>
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useFilters,
      useGlobalFilter
    );

  return (
    <div className="fundDetail dashboard column flex">
      <div className="row alignCenter justifySpaceBetween flex mb48">
        <div className="inlineFlex row alignCenter backIcon">
          <ion-icon
            name="arrow-back-outline"
            onClick={() => {
              setSelectedFund(undefined);
            }}
          ></ion-icon>
          <div className="f24">{selectedFund.name}</div>
        </div>
        <div className="inlineFlex">
          {selectedTab.addButtonLabel && selectedTab.addButtonLabel.length ? (
            <Button type="submit">{selectedTab.addButtonLabel}</Button>
          ) : null}
        </div>
      </div>
      <div className="tabs">
        <Tabs
          onSelect={(index) => {
            setSelectedTab(tabsData[index]);
          }}
        >
          <TabList className="mb24 noPadding">
            {tabsData &&
              tabsData.length &&
              tabsData.map((tab) => {
                return <Tab>{tab.label}</Tab>;
              })}
          </TabList>

          <TabPanel>
            <div className="iframeLoader flex flex1">
              <iframe
                title="Dashboard"
                className="dashboard__frame"
                src={selectedFund.metrics}
                style={{
                  display: "flex",
                  flex: 1,
                }}
              ></iframe>
            </div>
          </TabPanel>
          <TabPanel>
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </TabPanel>
          <TabPanel>
            {/* <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table> */}
          </TabPanel>
          <TabPanel>
            {/* <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table> */}
          </TabPanel>
          <TabPanel>
            {/* <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table> */}
          </TabPanel>
        </Tabs>
      </div>
      {modalVisibility ? (
        <Modal
          open={modalVisibility}
          onClose={() => setModalVisibility(false)}
          title="Cap Table"
          actions={[
            <Button
              type="button"
              size="lg"
              variant="link"
              onClick={() => setModalVisibility(false)}
            >
              Close
            </Button>,
          ]}
        >
          <Tabs>
            <TabList className="mb24 noPadding">
              {selectedCompany &&
              selectedCompany.capTable &&
              selectedCompany.capTable.length
                ? selectedCompany.capTable.map((capTableObj) => {
                    return <Tab>{capTableObj.label}</Tab>;
                  })
                : null}
            </TabList>
            {selectedCompany &&
            selectedCompany.capTable &&
            selectedCompany.capTable.length
              ? selectedCompany.capTable.map((capTableObj) => {
                  return (
                    <TabPanel>
                      <iframe
                        title="Dashboard"
                        className="dashboard__frame"
                        src={capTableObj.uri}
                        frameborder="0"
                      ></iframe>
                    </TabPanel>
                  );
                })
              : null}
          </Tabs>
        </Modal>
      ) : null}
    </div>
  );
};
