import React, { useState, useEffect } from 'react';
import cx from 'classnames';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';

import Button from '../../../components/Button';
import StatusScreen from '../../../components/StatusScreen';
import FormBuilder from '../../../components/FormBuilder';
import Modal from '../../../components/Modal';
import Loader from '../../../components/Loader';

import CompanyDetailsForm from './companyDetailsForm';

import {
  postCompany,
  resetPostCompanyStatus,
  getCompanyById,
  updateCompany,
} from './../actions';
import { getTemplates } from './../../templates/actions';

import './index.scss';

const emailValidate = (email) => {
  var pattern = /^((\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)\s*[,]{0,1}\s*)+$/i;

  if (!pattern.test(email)) {
    return false;
  }

  return true;
};

const blankFormData = {
  basic: {
    name: '',
    ownerName: '',
    founderEmail: '',
    partnerEmail: '',
    website: '',
    googleDataStudioURL: '',
    address: '',
    city: '',
    state: '',
    country: '',
  },
  sheets: {
    FINANCIAL: {
      order: 0,
      name: 'Financial',
      rows: [],
    },
    PERFORMANCE: {
      order: 1,
      name: 'Business',
      rows: [],
    },
    COMMENTS: {
      order: 2,
      name: 'Comments',
      rows: [],
    },
    COMPLIANCE: {
      order: 3,
      name: 'Compliance',
      rows: [],
    },
    MISCELLANEOUS: {
      order: 4,
      name: 'Miscellaneous',
      rows: [],
    },
  },
};

const validateDetails = (values) => {
  let err = {};
  if (!values.name) {
    err.name = '*Please enter a name';
  }

  if (!values.ownerName) {
    err.ownerName = "*Please enter a founder's name";
  }

  if (!values.founderEmail) {
    err.founderEmail = `*Please enter a founder's email`;
  } else if (!emailValidate(values.founderEmail)) {
    err.founderEmail = '*Please enter a valid email address';
  }

  if (!values.partnerEmail) {
    err.partnerEmail = `*Please enter a parnter's email`;
  } else if (!emailValidate(values.partnerEmail)) {
    err.partnerEmail = '*Please enter a valid email address';
  }

  return err;
};

const getTypeByIndex = (index) => {
  switch (index) {
    case 0:
      return 'FINANCIAL';
    case 1:
      return 'PERFORMANCE';
    case 2:
      return 'COMMENTS';
    case 3:
      return 'COMPLIANCE';
    case 4:
      return 'MISCELLANEOUS';

    default:
      return null;
  }
};

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const companyAdd = useSelector((state) => state.companyAdd);

  const templateList = useSelector((state) => state.templates.list || []);

  const selectedCompany = useSelector(
    (state) => state.companyAdd.selectedCompany || {}
  );

  const [status, setStatus] = useState(null);
  const [statusType, setStatusType] = useState(null);
  const [statusMessage, setStatusMessage] = useState(null);
  const [statusActions, setStatusActions] = useState([]);
  const [errors, setErrors] = useState({});
  const [openIndex, setOpenIndex] = useState(0);
  const [isEdit, setEditState] = useState(false);
  const [formData, setFormData] = useState(blankFormData);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [selectedTemplate, setTemplate] = useState(null);

  const formSheetKeys = [
    'FINANCIAL',
    'PERFORMANCE',
    'COMMENTS',
    'COMPLIANCE',
    'MISCELLANEOUS',
  ];

  useEffect(() => {
    if (
      selectedCompany &&
      selectedCompany.basic &&
      selectedCompany.basic.id &&
      isEdit
    ) {
      const newFormData = {
        basic: {
          name: selectedCompany.basic.name || '',
          ownerName: selectedCompany.basic.ownerName,
          founderEmail: selectedCompany.basic.founderEmail,
          partnerEmail: selectedCompany.basic.partnerEmail,
          website: selectedCompany.basic.website,
          googleDataStudioURL: selectedCompany.basic.googleDataStudioURL || '',
          address: selectedCompany.basic.address,
          city: selectedCompany.basic.city,
          state: selectedCompany.basic.state,
          country: selectedCompany.basic.country,
        },
        sheets: selectedCompany.sheets || {},
      };

      setFormData(newFormData);
    }
  }, [isEdit, selectedCompany]);

  useEffect(() => {
    dispatch(getTemplates());
  }, [dispatch]);

  useEffect(() => {
    if (params.companyId) {
      setEditState(true);
      dispatch(getCompanyById(params.companyId));
    }
  }, [dispatch, params.companyId]);

  useEffect(() => {
    if (companyAdd.isPostCompanySuccess && companyAdd.company) {
      setStatus(true);
      setStatusType('success');
      setStatusMessage(`${companyAdd.company.name} is successfully created`);
      setStatusActions([
        <Button
          type="button"
          size="lg"
          onClick={() => {
            setStatus(false);
            setFormData(blankFormData);
            setOpenIndex(0);
          }}
        >
          Add Another Company
        </Button>,
        <Button
          type="button"
          size="lg"
          variant="outlined"
          onClick={() => {
            setStatus(false);
            history.push('/companies');
          }}
        >
          Not Now
        </Button>,
      ]);
      dispatch(resetPostCompanyStatus());
    } else if (companyAdd.isPostCompanyfail) {
      setStatus(true);
      setStatusType('error');
      setStatusMessage(`Failed to create company`);
      setStatusActions([
        <Button
          type="button"
          size="lg"
          variant="outlined"
          onClick={() => {
            setStatus(false);
          }}
        >
          Back
        </Button>,
      ]);
      dispatch(resetPostCompanyStatus());
    }

    if (companyAdd.isUpdatingCompanySuccess && companyAdd.updatedCompany) {
      setStatus(true);
      setStatusType('success');
      setStatusMessage(
        `${companyAdd.updatedCompany.name} is successfully updated`
      );
      setStatusActions([
        <Button
          type="button"
          size="lg"
          variant="outlined"
          onClick={() => {
            setStatus(false);
            history.push('/companies');
          }}
        >
          Go to list
        </Button>,
      ]);
      dispatch(resetPostCompanyStatus());
    } else if (companyAdd.isUpdatingCompanyFail) {
      setStatus(true);
      setStatusType('error');
      setStatusMessage(`Failed to update company`);
      setStatusActions([
        <Button
          type="button"
          size="lg"
          variant="outlined"
          onClick={() => {
            setStatus(false);
          }}
        >
          Back
        </Button>,
      ]);
      dispatch(resetPostCompanyStatus());
    }
  }, [dispatch, history, companyAdd]);

  const handleSubmitClick = (event) => {
    event.preventDefault();

    const err = validateDetails(formData.basic);
    setErrors(err);

    if (Object.keys(err).length) {
      return;
    }

    if (isEdit && params.companyId) {
      dispatch(updateCompany(params.companyId, formData));
    } else {
      dispatch(
        postCompany({
          ...formData,
          sheets: {
            ...formData.sheets,
            FINANCIAL: {
              ...formData.sheets.FINANCIAL,
              rows: [
                {
                  key: 'reportingDate',
                  name: 'Reporting Date',
                  type: 'month_year',
                  description: 'Reporting date',
                  isRequired: true,
                  isMulti: false,
                  isFixed: true,
                  options: '',
                  pastDataKey: '',
                  pastDataHintText: '',
                },
                ...formData.sheets.FINANCIAL.rows,
              ],
            },
          },
        })
      );
    }
  };

  const handleSheetFieldChange = (type) => (data) => {
    setFormData({
      ...formData,
      sheets: {
        ...formData.sheets,
        [type]: {
          ...formData.sheets[type],
          rows: data,
        },
      },
    });
  };

  const handleSheetNameChange = (type) => (name) => {
    setFormData({
      ...formData,
      sheets: {
        ...formData.sheets,
        [type]: {
          ...formData.sheets[type],
          name: name,
        },
      },
    });
  };

  const handleSelectTemplate = () => {
    setModalVisibility(true);
  };

  const handleTemplate = () => {
    setModalVisibility(false);

    if (!selectedTemplate) return;

    const type = getTypeByIndex(openIndex);

    setFormData({
      ...formData,
      sheets: {
        ...formData.sheets,
        [type]: {
          ...formData.sheets[type],
          rows: [
            ...(formData.sheets[type] && formData.sheets[type].rows
              ? formData.sheets[type].rows
              : []),
            ...selectedTemplate.templateJson,
          ],
        },
      },
    });
    setTemplate(null);
  };

  const handleTemplateSave = (key, index) => () => {
    if (index === formSheetKeys.length - 1) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index + 1);
    }
  };

  const handleOpenCard = (cardIndex) => {
    setOpenIndex(cardIndex);
  };

  const renderFormCard = (sheetkey, index) => {
    const form = formData.sheets[sheetkey] || {};
    return (
      <div className="company-add__form__card">
        <Button
          variant="text-link"
          className="company-add__form__card__header"
          onClick={() => handleOpenCard(index)}
        >
          <h6 className="subtitle">{sheetkey}</h6>
          <label className="captionTextError">{errors[sheetkey]}</label>
        </Button>

        {openIndex === index ? (
          <FormBuilder
            key={sheetkey}
            isFormEdit={isEdit}
            fields={form.rows || []}
            formName={form.name || ''}
            onFieldChange={handleSheetFieldChange(sheetkey)}
            onSubmit={handleTemplateSave(sheetkey, index)}
            showAction={index !== formSheetKeys.length - 1}
            actionText={index === formSheetKeys.length - 1 ? 'Save' : 'Next'}
            headerLink={
              <Button
                variant="outlined"
                size="sm"
                className="company-add__form__card__header__link"
                onClick={() => handleSelectTemplate()}
              >
                Select Template
              </Button>
            }
            onOrderChange={handleSheetFieldChange(sheetkey)}
            allowNameEdit={true}
            onNameChange={handleSheetNameChange(sheetkey)}
          />
        ) : null}
      </div>
    );
  };

  if (companyAdd.isFetchingCompanyByID) {
    return (
      <div className="app-loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className="company-add">
      {status ? (
        <StatusScreen
          message={statusMessage}
          type={statusType}
          actions={statusActions}
        />
      ) : (
        <>
          <PageHeader
            icon={<ion-icon name="business-outline"></ion-icon>}
            title={isEdit ? 'Edit Limited Partner' : 'Create a Limited Partner'}
            subtitle={isEdit ? 'Edit LP info' : 'LP Registration'}
          />
          <div className="company-add__form">
            <div className="company-add__form__card">
              <Button
                variant="text-link"
                className="company-add__form__card__header"
              >
                <ion-icon name="information-circle-outline"></ion-icon>
                <h6 className="subtitle">Limited Partner Details</h6>
              </Button>

              <CompanyDetailsForm
                onChange={setFormData}
                errors={errors}
                formData={formData}
                onErrorChange={setErrors}
              />
            </div>

            {/* {formSheetKeys.map(renderFormCard)} */}

            <div>
              <Button
                type="button"
                size="lg"
                onClick={handleSubmitClick}
                disabled={
                  companyAdd.isUpdatingCompany || companyAdd.isPostingCompany
                }
              >
                {companyAdd.isUpdatingCompany || companyAdd.isPostingCompany ? (
                  'Loading...'
                ) : (
                  <>{isEdit ? 'Update' : 'Submit'}</>
                )}
              </Button>
            </div>
          </div>
        </>
      )}

      {modalVisibility ? (
        <Modal
          open={modalVisibility}
          onClose={() => setModalVisibility(false)}
          title="Select Template"
          actions={[
            <Button
              type="button"
              size="lg"
              variant="link"
              onClick={() => setModalVisibility(false)}
            >
              Cancel
            </Button>,
            <Button type="button" size="lg" onClick={handleTemplate}>
              Save
            </Button>,
          ]}
        >
          <div className="template-list">
            {templateList && !templateList.length ? (
              <p className="bodyText">No existing templates available</p>
            ) : (
              <ul>
                {templateList.map((template) => {
                  return (
                    <li
                      key={template.id}
                      className={cx({
                        selected:
                          selectedTemplate &&
                          selectedTemplate.id === template.id,
                      })}
                    >
                      {/* eslint-disable-next-line */}
                      <a onClick={() => setTemplate(template)}>
                        {template.templateName}
                      </a>
                      {selectedTemplate &&
                      selectedTemplate.id === template.id ? (
                        <ion-icon name="checkmark-circle-outline"></ion-icon>
                      ) : null}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </Modal>
      ) : null}
    </div>
  );
};
