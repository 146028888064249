import React, { useState } from 'react';
import './index.scss';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Button from '../../../components/Button';
import { useTable, useGlobalFilter, useFilters } from 'react-table';
import format from 'date-fns/format';
import Table from './Table';
import Modal from '../../../components/Modal';

export default ({setSelectedCompany, selectedCompany}) => {
  const [modalVisibility, setModalVisibility] = useState(false);
    console.log('selectedCompany',selectedCompany);
  // const selectedFund = useSelector((state) => state.layout.fund);
  const data = React.useMemo(() => selectedCompany.realisations, [selectedCompany.realisations]);
  const tabsData = [{
    label: 'Metrics Dashboard',
    addButtonLabel: undefined
  },{
    label: 'Realisations',
    addButtonLabel: 'Add Realisation +'
  },{
    label: 'Investment Rounds',
    addButtonLabel: 'Add Investment Round +'
  }];
  const [selectedTab, setSelectedTab] = useState(tabsData[0]);
  const columns = React.useMemo(
    () => [
      {
        Header: 'Sold To',
        accessor: 'investorName',
      },
      {
        Header: 'Shares Sold',
        accessor: 'numberOfShares',
      },
      {
        Header: 'Amount Sold',
        accessor: 'investmentAmount',
      },
      {
        Header: 'Share Price',
        accessor: 'sharePrice',
      },
      {
        accessor: 'id',
        Cell: (props) => {
          return <>
            <Button variant='outlined' size='s' onClick={()=>{
                debugger
            }}>Edit</Button>
          </>
        },
      },
    ],
    // eslint-disable-next-line
    []
  );

  const investmentData = React.useMemo(() => selectedCompany.investmentRounds, [selectedCompany.investmentRounds]);
  const investmentColumns = React.useMemo(
    () => [
      {
        Header: 'Round',
        accessor: 'roundName',
      },
      {
        Header: 'Date',
        accessor: 'date',
      },
      {
        Header: '% Stake of Prime',
        accessor: 'stake',
      },
      {
        Header: 'Total Round Size',
        accessor: 'totalRoundSize',
      },
      {
        accessor: 'id',
        Header: 'Cap Table',
        Cell: (props) => {
          return <>
            <Button variant='outlined' size='s' onClick={()=>{
              setModalVisibility(true);
            }}>View Cap Table</Button>
          </>
        },
      },
    ],
    // eslint-disable-next-line
    []
  );
  return (
    <div className="companyDetail dashboard column flex">
      <div className='row alignCenter justifySpaceBetween flex mb48'>
        <div className='inlineFlex row alignCenter backIcon'>
            <ion-icon name="arrow-back-outline" onClick={()=>{
                setSelectedCompany(undefined)
            }}></ion-icon>
            <div className='f24'>{selectedCompany.name}</div>
        </div>
        <div className='inlineFlex'>
          {
            selectedTab.addButtonLabel && selectedTab.addButtonLabel.length 
            ? 
              <Button type="submit">
                {selectedTab.addButtonLabel}
              </Button>
            : 
              null
          }
        </div>
      </div>
      <div className='tabs'>
      <Tabs onSelect={(index)=>{
          setSelectedTab(tabsData[index]);
        }}>
          <TabList className='mb24 noPadding'>
            {
              tabsData && tabsData.length && tabsData.map((tab)=>{
                return <Tab>{tab.label}</Tab>
              })
            }
          </TabList>

          <TabPanel>
            <div className='iframeLoader flex flex1'>
              <iframe
                title="Dashboard"
                className="dashboard__frame"
                src={selectedCompany.metrics}
                style={{
                  display:'flex',
                  flex:1
                }}
              >
                Loading
              </iframe>
            </div>
          </TabPanel>
          <TabPanel>
            <Table data={data} columns={columns}/>
          </TabPanel>
          <TabPanel>
            <Table data={investmentData} columns={investmentColumns} />
          </TabPanel>
        </Tabs>
      </div>
      {
        modalVisibility 
          ? 
            <Modal
              open={modalVisibility}
              onClose={() => setModalVisibility(false)}
              title="Cap Table"
              actions={[
                <Button
                  type="button"
                  size="lg"
                  variant="link"
                  onClick={() => setModalVisibility(false)}
                >
                  Close
                </Button>,
              ]}
            >
              <Tabs>
                <TabList className='mb24 noPadding'>
                  {
                    selectedCompany && selectedCompany.capTable && selectedCompany.capTable.length ?
                      selectedCompany.capTable.map((capTableObj)=>{
                        return <Tab>{capTableObj.label}</Tab>
                      }) : null
                  }
                </TabList>
                {
                  selectedCompany && selectedCompany.capTable && selectedCompany.capTable.length ?
                    selectedCompany.capTable.map((capTableObj)=>{
                      return <TabPanel>
                        <iframe
                          title="Dashboard"
                          className="dashboard__frame"
                          src={capTableObj.uri}
                          frameborder="0"
                        ></iframe>
                      </TabPanel>
                    }) : null
                }
              </Tabs>
            </Modal>
          :
            null
      }
    </div>
  );
};
