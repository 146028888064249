import React from 'react';
import Input from '../../../components/Input';

import './index.scss';

export default ({ errors, formData, onChange, onErrorChange }) => {
  const handleInputChange = (event) => {
    onChange({
      ...formData,
      basic: {
        ...formData.basic,
        [event.target.name]: event.target.value,
      },
    });
    onErrorChange({
      ...errors,
      [event.target.name]: '',
    });
  };

  return (
    <form noValidate>
      <div className="company-add__form__inputs">
        <div className="form-control">
          <label className="labelText">
            Fund Name
            <p className="error">*</p>
          </label>
          <Input
            type="text"
            name="name"
            onChange={handleInputChange}
            value={formData.basic.name}
            error={errors.name}
          />
        </div>
        <div className="form-control">
          <label className="labelText">
            Fund Size
            <p className="error">*</p>
          </label>
          <Input
            type="text"
            name="size"
            onChange={handleInputChange}
            value={formData.basic.size}
            error={errors.size}
          />
        </div>
        <div className="form-control">
          <label className="labelText">
            Capital Called
            <p className="error">*</p>
          </label>
          <Input
            type="text"
            name="capitalCalled"
            onChange={handleInputChange}
            value={formData.basic.capitalCalled}
            error={errors.capitalCalled}
          />
        </div>
        <div className="form-control">
          <label className="labelText">Capital To Call</label>
          <Input
            type="text"
            name="capitalToCall"
            onChange={handleInputChange}
            value={formData.basic.capitalToCall}
            error={errors.capitalToCall}
          />
        </div>
        <div className="form-control">
          <label className="labelText">Metrics URL</label>
          <Input
            type="text"
            name="googleDataStudioURL"
            onChange={handleInputChange}
            value={formData.basic.googleDataStudioURL}
            error={errors.googleDataStudioURL}
          />
        </div>
      </div>
    </form>
  );
};
