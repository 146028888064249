import React from 'react';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import country from './../../../constants/country';

import './index.scss';

export default ({ errors, formData, onChange, onErrorChange }) => {
  const handleInputChange = (event) => {
    onChange({
      ...formData,
      basic: {
        ...formData.basic,
        [event.target.name]: event.target.value,
      },
    });
    onErrorChange({
      ...errors,
      [event.target.name]: '',
    });
  };

  return (
    <form noValidate>
      <div className="company-add__form__inputs">
        <div className="form-control">
          <label className="labelText">
            Company Name
            <p className="error">*</p>
          </label>
          <Input
            type="text"
            name="name"
            onChange={handleInputChange}
            value={formData.basic.name}
            error={errors.name}
          />
        </div>
        <div className="form-control">
          <label className="labelText">
            % Stake
            <p className="error">*</p>
          </label>
          <Input
            type="text"
            name="ownerName"
            onChange={handleInputChange}
            value={formData.basic.ownerName}
            error={errors.ownerName}
          />
        </div>
        <div className="form-control">
          <label className="labelText">
            Amount Invested
            <p className="error">*</p>
          </label>
          <Input
            type="email"
            name="founderEmail"
            onChange={handleInputChange}
            value={formData.basic.founderEmail}
            error={errors.founderEmail}
          />
        </div>
        <div className="form-control">
          <label className="labelText">
            FMV of Company
            <p className="error">*</p>
          </label>
          <Input
            type="email"
            name="partnerEmail"
            onChange={handleInputChange}
            value={formData.basic.partnerEmail}
            error={errors.partnerEmail}
          />
        </div>
        <div className="form-control">
          <label className="labelText">FMV of Amount Invested</label>
          <Input
            type="text"
            name="website"
            onChange={handleInputChange}
            value={formData.basic.website}
            error={errors.website}
          />
        </div>
      </div>
    </form>
  );
};
