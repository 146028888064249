import React, { useState } from "react";
import "./index.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Button from "../../../components/Button";
import { useTable, useGlobalFilter, useFilters } from "react-table";
import format from "date-fns/format";
import Modal from "../../../components/Modal";
import Table from "./Table";

export default ({ setSelectedLP, selectedLP }) => {
  // const selectedLP = useSelector((state) => state.layout.fund);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(undefined);
  const data = React.useMemo(
    () => selectedLP.distributions,
    [selectedLP.distributions]
  );
  const usersData = React.useMemo(() => selectedLP.users, [selectedLP.users]);
  const fundsData = React.useMemo(
    () => selectedLP.lpFunds,
    [selectedLP.lpFunds]
  );
  const tabsData = [
    {
      label: "Distributions",
      addButtonLabel: "Add Distribution +",
    },
    {
      label: "Users",
      addButtonLabel: "Add User +",
    },
    {
      label: "Funds",
      addButtonLabel: "Add Fund +",
    },
  ];
  const [selectedTab, setSelectedTab] = useState(tabsData[0]);
  console.log("selectedLP", selectedLP, "selectedCompany", selectedCompany);
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Distribution Amount",
        accessor: "amount",
      },
      {
        Header: "Enity",
        accessor: "entity",
      },
      {
        accessor: "id",
        Cell: ({ value }) => (
          <div className="company__table__actions flex row flexCenter">
            <div className="primary editLink">Edit</div>
            <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line
    []
  );
  const usersColumns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Role",
        accessor: "role",
      },
      {
        Header: "Added On",
        accessor: "createdAt",
      },
      {
        accessor: "id",
        Cell: ({ value }) => (
          <div className="company__table__actions flex row flexCenter">
            <div className="primary editLink">Edit</div>
            <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line
    []
  );
  const fundsColumns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "% Invested",
        accessor: "percentageInvested",
      },
      {
        Header: "NAV",
        accessor: "nav",
      },
      {
        Header: "IRR",
        accessor: "irr",
      },
      {
        Header: "Commitment Amount",
        accessor: "commitedAmount",
      },
      {
        Header: "Commitment not drawn",
        accessor: "notDrawnAmount",
      },
      {
        accessor: "id",
        Cell: ({ value }) => (
          <div className="company__table__actions flex row flexCenter">
            <div className="primary editLink">Edit</div>
            <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useFilters,
      useGlobalFilter
    );

  return (
    <div className="fundDetail dashboard column flex">
      <div className="row alignCenter justifySpaceBetween flex mb48">
        <div className="inlineFlex row alignCenter backIcon">
          <ion-icon
            name="arrow-back-outline"
            onClick={() => {
              setSelectedLP(undefined);
            }}
          ></ion-icon>
          <div className="f24">{selectedLP.name}</div>
        </div>
        <div className="inlineFlex">
          {selectedTab.addButtonLabel && selectedTab.addButtonLabel.length ? (
            <Button type="submit">{selectedTab.addButtonLabel}</Button>
          ) : null}
        </div>
      </div>
      <div className="tabs">
        <Tabs
          onSelect={(index) => {
            setSelectedTab(tabsData[index]);
          }}
        >
          <TabList className="mb24 noPadding">
            {tabsData &&
              tabsData.length &&
              tabsData.map((tab) => {
                return <Tab>{tab.label}</Tab>;
              })}
          </TabList>

          <TabPanel>
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </TabPanel>
          <TabPanel>
            <Table data={usersData} columns={usersColumns} />
          </TabPanel>
          <TabPanel>
            <Table data={fundsData} columns={fundsColumns} />
          </TabPanel>
          <TabPanel>
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </TabPanel>
          <TabPanel>
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </TabPanel>
        </Tabs>
      </div>
      {modalVisibility ? (
        <Modal
          open={modalVisibility}
          onClose={() => setModalVisibility(false)}
          title="Cap Table"
          actions={[
            <Button
              type="button"
              size="lg"
              variant="link"
              onClick={() => setModalVisibility(false)}
            >
              Close
            </Button>,
          ]}
        >
          <Tabs>
            <TabList className="mb24 noPadding">
              {selectedCompany &&
              selectedCompany.capTable &&
              selectedCompany.capTable.length
                ? selectedCompany.capTable.map((capTableObj) => {
                    return <Tab>{capTableObj.label}</Tab>;
                  })
                : null}
            </TabList>
            {selectedCompany &&
            selectedCompany.capTable &&
            selectedCompany.capTable.length
              ? selectedCompany.capTable.map((capTableObj) => {
                  return (
                    <TabPanel>
                      <iframe
                        title="Dashboard"
                        className="dashboard__frame"
                        src={capTableObj.uri}
                        frameborder="0"
                      ></iframe>
                    </TabPanel>
                  );
                })
              : null}
          </Tabs>
        </Modal>
      ) : null}
    </div>
  );
};
