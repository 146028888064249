import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Auth from '../features/auth/components';
import Layout from '../features/layout/components';
import Metrices from '../features/metrices/components';
import MetricForm from '../features/metricForm/components';
import Users from '../features/users/components';
import UsersAdd from '../features/usersAdd/components';
import Companies from '../features/companies/components';
import Funds from '../features/funds/components';
import CompanyAdd from '../features/companyAdd/components';
import AddGP from '../features/addGp/components';
import AddLP from '../features/addLp/components';
import FundsAdd from '../features/fundsAdd/components';
import ChangePassword from '../features/changePassword/components';
import Templates from '../features/templates/components';
import TemplateForm from '../features/templateForm/components';
import Dashboard from '../features/dashboard/components';
import FundsDashboard from '../features/fundsDashboard/components';
import CompaniesDashboard from '../features/companiesDashboard/components';
import LPDashboard from '../features/LPDashboard/components';
import Documents from '../features/Documents/components';
import GPDashboard from '../features/GPDashboard/components';


import { autoLogin, getUser } from '../features/login/actions';
import BlankState from '../components/BlankState';
import NoData from '../assets/images/no-data.svg';

const PrivateRoute = ({ children, ...rest }) => {
  const login = useSelector((state) => state.login);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        login.isUserLoginSuccess ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default () => {
  const login = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const [isVerifyingAuth, setAuthVerifyStatus] = useState(true);

  useEffect(() => {
    // Attempt auto-login
    dispatch(autoLogin());
  }, [dispatch]);

  useEffect(() => {
    if (login.isUserLoginSuccess) {
      dispatch(getUser());
    } else {
      dispatch(autoLogin(true));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login.isUserLoginSuccess]);

  useEffect(() => {
    setAuthVerifyStatus(false);
  }, [
    login.isUserLoginSuccess,
    login.isUserLoginFail,
    login.isUserLogoutSuccess,
  ]);

  if (isVerifyingAuth) {
    return null;
  }

  return (
    <Router>
      <Switch>
        <PrivateRoute path="/dashboard" exact>
          <Layout>
            <Dashboard />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/" exact>
          <Layout>
            <div className="dashboard__blank">
              <BlankState
                text="Prime Venture Partners"
                icon={<img src={NoData} alt="No data"></img>}
              />
            </div>
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/metrics" exact>
          <Layout>
            <Metrices />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/users" exact>
          <Layout>
            <Users />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/users/add" exact>
          <Layout>
            <UsersAdd />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/users/edit/:userId" exact>
          <Layout>
            <UsersAdd />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/companies" exact>
          <Layout>
            <Companies />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/add/company" exact>
          <Layout>
            <CompanyAdd />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/add/fund" exact>
          <Layout>
            <FundsAdd />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/add/gp" exact>
          <Layout>
            <AddGP />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/add/lp" exact>
          <Layout>
            <AddLP />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/gp-documents" exact>
          <Layout>
            <Documents type='gp' />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/lp-documents" exact>
          <Layout>
            <Documents type='lp' />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/companies/edit/:companyId" exact>
          <Layout>
            <CompanyAdd />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/funds/edit/:fundId" exact>
          <Layout>
            <FundsAdd />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/change-password" exact>
          <Layout>
            <ChangePassword />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/templates" exact>
          <Layout>
            <Templates />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/templates/add" exact>
          <Layout>
            <TemplateForm />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/templates/edit/:templateId" exact>
          <Layout>
            <TemplateForm />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/funds" exact>
          <Layout>
            <Funds />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/funds-dashboard" exact>
          <Layout>
            <FundsDashboard />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/companies-dashboard" exact>
          <Layout>
            <CompaniesDashboard />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/lp-dashboard" exact>
          <Layout>
            <LPDashboard />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/gp-dashboard" exact>
          <Layout>
            <GPDashboard />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/users" exact>
          <Layout>
            <></>
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/companies" exact>
          <Layout>
            <></>
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/general-partners" exact>
          <Layout>
            <></>
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/limited-partners" exact>
          <Layout>
            <></>
          </Layout>
        </PrivateRoute>
        <PrivateRoute path="/overview" exact>
          <Layout>
            <></>
          </Layout>
        </PrivateRoute>
        <Route path="/">
          <Auth />
        </Route>
      </Switch>
    </Router>
  );
};
