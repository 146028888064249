import React from 'react';
import './index.scss';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Button from '../../../components/Button';
import { useTable, useGlobalFilter, useFilters } from 'react-table';
import format from 'date-fns/format';

export default ({setSelectedFund}) => {
  // const selectedFund = useSelector((state) => state.layout.fund);
  const response = require('../mockData.json')
  const data = React.useMemo(() => response, [response]);
  const columns = React.useMemo(
    () => [
      {
        Header: 'Fund Name',
        accessor: 'name',
      },
      {
        Header: 'Fund Size',
        accessor: 'size',
        Cell: ({ value }) => (value ? <>{value}</> : '--'),
      },
      {
        Header: 'Capital Called',
        accessor: 'calledCapital',
        Cell: ({ value }) => (value ? <>{value}</> : '--'),
      },
      {
        Header: 'Capital to call',
        accessor: 'capitalToCall',
      },
      {
        Header: 'View Metrics',
        Cell: (props) => {
          return <>
            <Button variant='outlined' size='s' onClick={()=>{
                setSelectedFund(props.data[props.row.index]);
            }}>View</Button>
          </>
        },
      },
      {
        accessor: 'id',
        Cell: ({ value }) => (
          <div className="company__table__actions flex row flexCenter">
            <div className='primary editLink'>Edit</div>
            <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter
  );

  return (
    <div className="dashboard column flex">
      <div className='row alignCenter justifySpaceBetween flex mb48'>
        <div className='inlineFlex f24'>Funds</div>
        <div className='inlineFlex'>
          <Button type="submit">
            Add New +
          </Button>
        </div>
      </div>
      <div className='table'>
        <table {...getTableProps()}>
            <thead>
            {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                    </th>
                ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
                prepareRow(row);
                return (
                <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                    return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                    })}
                </tr>
                );
            })}
            </tbody>
        </table>
      </div>
    </div>
  );
};
