import React from 'react';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import country from './../../../constants/country';

import './index.scss';

export default ({ errors, formData, onChange, onErrorChange }) => {
  const fundsArr = require("../../fundsDashboard/mockData.json")
  const funds = fundsArr.map((fund)=>{
    return {
      label:fund.name,
      value:fund.name
    }
  })
  
  const handleInputChange = (event) => {
    onChange({
      ...formData,
      basic: {
        ...formData.basic,
        [event.target.name]: event.target.value,
      },
    });
    onErrorChange({
      ...errors,
      [event.target.name]: '',
    });
  };

  return (
    <form noValidate>
      <div className="company-add__form__inputs">
        <div className="form-control">
          <label className="labelText">
            Name
            <p className="error">*</p>
          </label>
          <Input
            type="text"
            name="name"
            onChange={handleInputChange}
            value={formData.basic.name}
            error={errors.name}
          />
        </div>
        <div className="form-control">
          <label className="labelText">
            Funds
            <p className="error">*</p>
          </label>
            <Select
              options={funds}
              name="funds"
              onChange={()=>false}
              value={formData.funds}
              error={errors.funds}
              getOptionValue={(o) => o.value}
            />
        </div>
        <div className="form-control">
          <label className="labelText">
            Capital Commitment
            <p className="error">*</p>
          </label>
          <Input
            type="email"
            name="founderEmail"
            onChange={handleInputChange}
            value={formData.basic.founderEmail}
            error={errors.founderEmail}
          />
        </div>
        <div className="form-control">
          <label className="labelText">
            Dashboard Link
            <p className="error">*</p>
          </label>
          <Input
            type="email"
            name="partnerEmail"
            onChange={handleInputChange}
            value={formData.basic.partnerEmail}
            error={errors.partnerEmail}
          />
        </div>
      </div>
    </form>
  );
};
