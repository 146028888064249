import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, NavLink } from 'react-router-dom';
import { GoogleLogin } from 'react-google-login';

import Input from './../../../components/Input';
import Button from './../../../components/Button';
import { userLogin, userGoogleLogin } from './../actions';

import IconGoogle from './../../../assets/images/google.svg';

import './index.scss';

const emailValidate = (email) => {
  const pattern = new RegExp(
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/
  );

  if (!pattern.test(email)) {
    return false;
  }
  return true;
};

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const login = useSelector((state) => state.login);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    if (login.isUserLoginSuccess && !login.isFetchingUser) {
      history.push('/');
    }
  }, [history, login.isUserLoginSuccess, login.isFetchingUser]);

  useEffect(() => {
    if (login.isUserLoginFail) {
      setErrors({
        password: login.message,
      });
    }
  }, [login.isUserLoginFail, login.message]);

  const validate = (values) => {
    let err = {};
    if (!values.email) {
      err.email = 'Please enter an email';
    } else if (!emailValidate(values.email)) {
      err.email = 'Please enter a valid email address';
    }

    if (!values.password) {
      err.password = 'Please enter a password';
    }

    return err;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    const err = validate(formData);
    setErrors(err);

    if (!Object.keys(err).length) {
      dispatch(userLogin(formData));
    }

    return false;
  };

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    setErrors({
      ...errors,
      [event.target.name]: '',
    });
  };

  const handleGoogleSuccess = (response) => {
    const googleIdToken = response.getAuthResponse().id_token;

    dispatch(userGoogleLogin(googleIdToken));
  };

  const handleGoogleFailure = () => null;

  return (
    <div className="login">
      <div className="login__title">
        <p className="title">Welcome</p>
        <p className="bodyText">
          Login to <strong>Portfolio Metric Tool</strong>
        </p>
      </div>

      <form className="login__form" onSubmit={handleFormSubmit} noValidate>
        <div className="form-control">
          <Input
            placeholder="Email Address"
            type="email"
            name="email"
            onChange={handleInputChange}
            value={formData.email}
            prefix={<ion-icon name="mail-outline"></ion-icon>}
            error={errors.email}
          />
        </div>
        <div className="form-control">
          <Input
            placeholder="Password"
            type="password"
            name="password"
            onChange={handleInputChange}
            value={formData.password}
            prefix={<ion-icon name="lock-closed-outline"></ion-icon>}
            error={errors.password}
          />
        </div>
        <div className="form-control">
          <NavLink to="/forgot-password" className="login__form__forgot">
            Forgot Password?
          </NavLink>
        </div>
        <div className="form-control">
          <Button type="submit">Login</Button>
        </div>
        <div className="form-control">
          <div className="seperator">
            <span className="seperator__text">Or</span>
          </div>
        </div>

        <div className="form-control">
          <GoogleLogin
            clientId="138212150272-cjqbu6993kbsg1m9qr9762c0aa15j807.apps.googleusercontent.com"
            buttonText="Sign in with Google"
            onSuccess={handleGoogleSuccess}
            onFailure={handleGoogleFailure}
            cookiePolicy={'single_host_origin'}
            isSignedIn={true}
            render={(renderProps) => (
              <Button
                type="button"
                className="btnGoogle"
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                <img src={IconGoogle} alt="Login with google" width={20} />
                Login with Google
              </Button>
            )}
          />
        </div>
      </form>
    </div>
  );
};
