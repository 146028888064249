import axios from 'axios';
import config from '../../../config';

const getFundsListStart = () => {
  return {
    type: 'GET_FUNDS_LIST_START',
  };
};

const getFundsListSuccess = ({ data }) => {
  return {
    type: 'GET_FUNDS_LIST_SUCCESS',
    data,
  };
};

const getFundsListFail = () => {
  return {
    type: 'GET_FUNDS_LIST_FAIL',
  };
};

export function getFundsList() {
  return (dispatch, getState) => {
    dispatch(getFundsListStart());

    const { login } = getState();

    axios
      .get(`${config.API_URL}/funds/all`, {
        headers: {
          Authorization: `Bearer ${login.token}`,
        },
      })
      .then((resp) => {
        dispatch(getFundsListSuccess({ data: resp.data }));
      })
      .catch((ex) => {
        console.log('Failed to post user', ex);
        dispatch(getFundsListFail());
      });
  };
}

// Send reminder
export const sendReminder = (fundId, ccPartner) => {
  return (dispatch, getState) => {
    dispatch({
      type: 'SEND_REMINDER_LOADING',
      payload: true,
    });

    const { login } = getState();
    axios
      .post(
        `${config.API_URL}/funds/${fundId}/reminder`,
        {
          ccPartner,
        },
        {
          headers: {
            Authorization: `Bearer ${login.token}`,
          },
        }
      )
      .then((resp) => {
        dispatch({
          type: 'SEND_REMINDER',
          payload: resp.data,
        });
      })
      .catch((ex) => {
        console.log('Failed to send reminder', ex);
        dispatch({
          type: 'SEND_REMINDER',
          payload: ex,
          error: true,
        });
      });
  };
};

export const resetReminderStatus = () => {
  return (dispatch) => {
    dispatch({ type: 'RESET_REMINDER_STATUS', payload: false });
  };
};
